import { RelayEnvironmentProvider } from "react-relay";
import styled from "styled-components";
import newEnvironment from "../../relay/RelayEnvironment";
import ZakekePreviewIframe from "./zakekePreviewIframe";

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const Preview = () => {
    const params = new URLSearchParams(window.location.hash.split('?')[1]);

    const product = params.get('product');
    const token = params.get('token');

    if (!product || !token) {
        throw new Error('Missing product or token');
    }

    const productID = parseInt(product, 10);
    if (isNaN(productID)) {
        throw new Error('Invalid product');
    }

    const relayEnvironment = newEnvironment();

    return (
        <RelayEnvironmentProvider environment={relayEnvironment} >
            <Container>
                <ZakekePreviewIframe productID={productID} token={token} />
            </Container>
        </RelayEnvironmentProvider>
    );
};

export default Preview;