/**
 * @generated SignedSource<<0965f7c111ace032d7a1941470d7b119>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarketplaceOrderDetailStatus = "COMPLETE" | "NEW" | "WAITING" | "WAITING_FOR_SPLIT";
export type splitQuery$variables = {
  accessToken: string;
};
export type splitQuery$data = {
  readonly marketplaceOrderDetail: {
    readonly design: {
      readonly " $fragmentSpreads": FragmentRefs<"DesignPreviews_previews">;
    } | null;
    readonly id: string;
    readonly quantity: number;
    readonly status: MarketplaceOrderDetailStatus;
  } | null;
};
export type splitQuery = {
  response: splitQuery$data;
  variables: splitQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessToken"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accessToken",
    "variableName": "accessToken"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "splitQuery",
    "selections": [
      {
        "alias": "marketplaceOrderDetail",
        "args": (v1/*: any*/),
        "concreteType": "MarketplaceOrderDetail",
        "kind": "LinkedField",
        "name": "marketplaceOrderDetailByAccessToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Design",
            "kind": "LinkedField",
            "name": "design",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DesignPreviews_previews"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "splitQuery",
    "selections": [
      {
        "alias": "marketplaceOrderDetail",
        "args": (v1/*: any*/),
        "concreteType": "MarketplaceOrderDetail",
        "kind": "LinkedField",
        "name": "marketplaceOrderDetailByAccessToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Design",
            "kind": "LinkedField",
            "name": "design",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SidePreview",
                "kind": "LinkedField",
                "name": "previews",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41b35ef4399d62b85dda9f8409b50a50",
    "id": null,
    "metadata": {},
    "name": "splitQuery",
    "operationKind": "query",
    "text": "query splitQuery(\n  $accessToken: String!\n) {\n  marketplaceOrderDetail: marketplaceOrderDetailByAccessToken(accessToken: $accessToken) {\n    id\n    status\n    quantity\n    design {\n      ...DesignPreviews_previews\n    }\n  }\n}\n\nfragment DesignPreviews_previews on Design {\n  previews {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "e35c518149322558091a56337a112ad4";

export default node;
