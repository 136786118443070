import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import fetchGraphQL from './fetchGraphQL';

export default function newEnvironment(authToken?: string) {
  async function fetchRelay(params: any, variables: any) {
    return fetchGraphQL(process.env.REACT_APP_GRAPHQL_URL, params.text, variables, authToken);
  }

  return new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
  })
}