import { FC } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
// @ts-ignore
import graphql from 'babel-plugin-relay/macro';
import isMobile from './isMobile';
import styled from 'styled-components';
import { zakekePreviewIframeQuery } from './__generated__/zakekePreviewIframeQuery.graphql';

const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0;
`;

interface Props {
    productID: number,
    token: string
}

const ZakekeIframe: FC<Props> = ({ productID, token }) => {
    const { marketplaceProductPreview } = useLazyLoadQuery<zakekePreviewIframeQuery>(
        graphql`
          query zakekePreviewIframeQuery($productID: Long!, $token: String!) {
            marketplaceProductPreview(productID: $productID, token: $token) {
              largeUrl,
              mobileUrl
            }
          }
        `,
        { productID, token },
    );

    if (!marketplaceProductPreview) {
        throw new Error(`Cannot load preview for product ${productID}`);
    }

    const zakekeIframeUrl = isMobile() ? marketplaceProductPreview.mobileUrl : marketplaceProductPreview.largeUrl;

    return (
        <StyledIframe allow="clipboard-read; clipboard-write; fullscreen; web-share; accelerometer; magnetometer; autoplay; encrypted-media; gyroscope; picture-in-picture; camera *; xr-spatial-tracking;" src={zakekeIframeUrl} />
    );
};

export default ZakekeIframe;