import { FC } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import newEnvironment from "../../relay/RelayEnvironment";
import { completeQuery } from "./__generated__/completeQuery.graphql";
import DesignPreviews from "../DesignPreviews";

const StyledDesignPreviews = styled(DesignPreviews)`
  @media (min-width: 983px) {
    margin-left: 200px;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  background: #181818;

  @media (min-width: 983px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 982px) {
    grid-auto-rows: auto auto;
  }
`;

const ContainerText = styled.div`
  padding: 10px;
`;

const H1 = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 56px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 0px;
  color: white;
`;

const P = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: white;
`;

const CompletePendingDesignButton = styled.button`
  background: white;
  padding: 13px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  cursor: pointer;
`;

interface Props {
  accessToken: string;
}

interface KeyData {
  accessToken: string;
  authToken: string;
}

const ZakekeDesignComplete: FC<Props> = ({ accessToken }) => {
  const { marketplaceOrderDetail } = useLazyLoadQuery<completeQuery>(
    graphql`
      query completeQuery($accessToken: String!) {
        marketplaceOrderDetail: marketplaceOrderDetailByAccessToken(
          accessToken: $accessToken
        ) {
          design {
            ...DesignPreviews_previews
          }
          relatedMarketplaceOrderDetails {
            status
            designerUrl
            quantity
            design {
              tempPreviewImageUrl
            }
          }
        }
      }
    `,
    { accessToken }
  );

  if (!marketplaceOrderDetail) {
    throw new Error(`Invalid access token ${accessToken}`);
  }

  if (!marketplaceOrderDetail.design) {
    throw new Error(`Invalid access token ${accessToken}`);
  }

  const pendingMarketplaceOrderDetail =
    marketplaceOrderDetail.relatedMarketplaceOrderDetails.find(
      (relatedMarketplaceOrderDetail) =>
        relatedMarketplaceOrderDetail.status === "NEW"
    );

  const completedDesignsQty =
    marketplaceOrderDetail.relatedMarketplaceOrderDetails.reduce(
      (acc, val) => acc + val.quantity,
      0
    );

  const totalQty = completedDesignsQty + (pendingMarketplaceOrderDetail ? pendingMarketplaceOrderDetail.quantity : 0);

  return (
    <Container>
      <ContainerText>
        {(pendingMarketplaceOrderDetail && (
          <>
            <H1>Design completed on {completedDesignsQty}/{totalQty} items</H1>
            <P>
                Your order is made of {totalQty} items and you still have to complete your design on {completedDesignsQty} items.
            </P>
            <CompletePendingDesignButton
              onClick={() => {
                window.location.href =
                  pendingMarketplaceOrderDetail.designerUrl;
              }}
            >
              Click here to keep designing the remaining items in your order
            </CompletePendingDesignButton>
          </>
        )) || (
          <>
            <H1>Design completed</H1>
            <P>The design has been sent to the merchant.</P>
          </>
        )}
      </ContainerText>

      <StyledDesignPreviews designQuery={marketplaceOrderDetail.design} />
    </Container>
  );
};

const ZakekeComplete: FC = () => {
  const { key } = useParams<{ key?: string }>();
  if (!key) {
    throw new Error("Missing key");
  }

  const { accessToken, authToken } = JSON.parse(atob(key)) as KeyData;

  const relayEnvironment = newEnvironment(authToken);

  if (!accessToken) {
    throw new Error("Missing access token");
  }

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <ZakekeDesignComplete accessToken={accessToken} />
    </RelayEnvironmentProvider>
  );
};

export default ZakekeComplete;
