/**
 * @generated SignedSource<<320197dbc716413ceba1d2cf7c30c3a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarketplaceOrderDetailStatus = "COMPLETE" | "NEW" | "WAITING" | "WAITING_FOR_SPLIT";
export type splitMarketplaceOrderDetailMutation$variables = {
  accessToken: string;
  updatedQuantity: number;
};
export type splitMarketplaceOrderDetailMutation$data = {
  readonly splitMarketplaceOrderDetail: {
    readonly designerUrl: string;
    readonly id: string;
    readonly status: MarketplaceOrderDetailStatus;
  };
};
export type splitMarketplaceOrderDetailMutation = {
  response: splitMarketplaceOrderDetailMutation$data;
  variables: splitMarketplaceOrderDetailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessToken"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updatedQuantity"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accessToken",
        "variableName": "accessToken"
      },
      {
        "kind": "Variable",
        "name": "updatedQuantity",
        "variableName": "updatedQuantity"
      }
    ],
    "concreteType": "MarketplaceOrderDetail",
    "kind": "LinkedField",
    "name": "splitMarketplaceOrderDetail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "designerUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "splitMarketplaceOrderDetailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "splitMarketplaceOrderDetailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "15608e903039c50cbe687e70f4a8402e",
    "id": null,
    "metadata": {},
    "name": "splitMarketplaceOrderDetailMutation",
    "operationKind": "mutation",
    "text": "mutation splitMarketplaceOrderDetailMutation(\n  $accessToken: String!\n  $updatedQuantity: Int!\n) {\n  splitMarketplaceOrderDetail(accessToken: $accessToken, updatedQuantity: $updatedQuantity) {\n    id\n    status\n    designerUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "32dfb75f89c99d562574f497d86252c2";

export default node;
