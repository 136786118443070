/**
 * @generated SignedSource<<96e22f4713ae5c6a46fc02dadcf80636>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarketplaceOrderDetailStatus = "COMPLETE" | "NEW" | "WAITING" | "WAITING_FOR_SPLIT";
export type completeQuery$variables = {
  accessToken: string;
};
export type completeQuery$data = {
  readonly marketplaceOrderDetail: {
    readonly design: {
      readonly " $fragmentSpreads": FragmentRefs<"DesignPreviews_previews">;
    } | null;
    readonly relatedMarketplaceOrderDetails: ReadonlyArray<{
      readonly design: {
        readonly tempPreviewImageUrl: string | null;
      } | null;
      readonly designerUrl: string;
      readonly quantity: number;
      readonly status: MarketplaceOrderDetailStatus;
    }>;
  } | null;
};
export type completeQuery = {
  response: completeQuery$data;
  variables: completeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessToken"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accessToken",
    "variableName": "accessToken"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "designerUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Design",
  "kind": "LinkedField",
  "name": "design",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tempPreviewImageUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "completeQuery",
    "selections": [
      {
        "alias": "marketplaceOrderDetail",
        "args": (v1/*: any*/),
        "concreteType": "MarketplaceOrderDetail",
        "kind": "LinkedField",
        "name": "marketplaceOrderDetailByAccessToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Design",
            "kind": "LinkedField",
            "name": "design",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DesignPreviews_previews"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MarketplaceOrderDetail",
            "kind": "LinkedField",
            "name": "relatedMarketplaceOrderDetails",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "completeQuery",
    "selections": [
      {
        "alias": "marketplaceOrderDetail",
        "args": (v1/*: any*/),
        "concreteType": "MarketplaceOrderDetail",
        "kind": "LinkedField",
        "name": "marketplaceOrderDetailByAccessToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Design",
            "kind": "LinkedField",
            "name": "design",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SidePreview",
                "kind": "LinkedField",
                "name": "previews",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MarketplaceOrderDetail",
            "kind": "LinkedField",
            "name": "relatedMarketplaceOrderDetails",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f1a481f5a91dc4fb2e45e0fd8fb4ce88",
    "id": null,
    "metadata": {},
    "name": "completeQuery",
    "operationKind": "query",
    "text": "query completeQuery(\n  $accessToken: String!\n) {\n  marketplaceOrderDetail: marketplaceOrderDetailByAccessToken(accessToken: $accessToken) {\n    design {\n      ...DesignPreviews_previews\n    }\n    relatedMarketplaceOrderDetails {\n      status\n      designerUrl\n      quantity\n      design {\n        tempPreviewImageUrl\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment DesignPreviews_previews on Design {\n  previews {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "6be54b975f5262ba44dbe192e571ebea";

export default node;
