/**
 * @generated SignedSource<<fcd40c02f8b6cac0aae4ec0b0edc3c63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MarketplaceOrderDetailStatus = "COMPLETE" | "NEW" | "WAITING" | "WAITING_FOR_SPLIT";
export type MarketplaceOrderStatus = "BLOCKED" | "CANCELED" | "COMPLETE" | "INCOMPLETE" | "NEW";
export type zakekeIframeQuery$variables = {
  accessToken: string;
};
export type zakekeIframeQuery$data = {
  readonly marketplaceOrderDetail: {
    readonly id: string;
    readonly iframeUrl: {
      readonly largeUrl: string;
      readonly mobileUrl: string;
    };
    readonly marketplaceOrder: {
      readonly status: MarketplaceOrderStatus;
    } | null;
    readonly status: MarketplaceOrderDetailStatus;
  } | null;
};
export type zakekeIframeQuery = {
  response: zakekeIframeQuery$data;
  variables: zakekeIframeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessToken"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accessToken",
    "variableName": "accessToken"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "MarketplaceIframe",
  "kind": "LinkedField",
  "name": "iframeUrl",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "largeUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobileUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "zakekeIframeQuery",
    "selections": [
      {
        "alias": "marketplaceOrderDetail",
        "args": (v1/*: any*/),
        "concreteType": "MarketplaceOrderDetail",
        "kind": "LinkedField",
        "name": "marketplaceOrderDetailByAccessToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MarketplaceOrderDetailed",
            "kind": "LinkedField",
            "name": "marketplaceOrder",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "zakekeIframeQuery",
    "selections": [
      {
        "alias": "marketplaceOrderDetail",
        "args": (v1/*: any*/),
        "concreteType": "MarketplaceOrderDetail",
        "kind": "LinkedField",
        "name": "marketplaceOrderDetailByAccessToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MarketplaceOrderDetailed",
            "kind": "LinkedField",
            "name": "marketplaceOrder",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "281be489304c0d346cc04316187451b1",
    "id": null,
    "metadata": {},
    "name": "zakekeIframeQuery",
    "operationKind": "query",
    "text": "query zakekeIframeQuery(\n  $accessToken: String!\n) {\n  marketplaceOrderDetail: marketplaceOrderDetailByAccessToken(accessToken: $accessToken) {\n    id\n    status\n    iframeUrl {\n      largeUrl\n      mobileUrl\n    }\n    marketplaceOrder {\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ecd6703e33785fcafaaf607a37076cf";

export default node;
