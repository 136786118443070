import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import newEnvironment from '../../relay/RelayEnvironment';
import ZakekeIframe from './zakekeIframe';

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

interface KeyData {
    accessToken: string,
    authToken: string
}

const Designer = () => {
    const { key } = useParams<{ key?: string }>();
    if (!key) {
        throw new Error('Missing key');
    }

    const { accessToken, authToken } = JSON.parse(atob(key)) as KeyData;

    const relayEnvironment = newEnvironment(authToken);

    if (!accessToken) {
        throw new Error('Missing access token');
    }

    return (
        <RelayEnvironmentProvider environment={relayEnvironment} >
            <Container>
                <ZakekeIframe accessToken={accessToken} urlKey={key} />
            </Container>
        </RelayEnvironmentProvider>
    );
};

export default Designer;