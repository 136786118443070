import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    background: #181818;

    @media (min-width: 983px) {
        grid-template-columns: auto auto;
    }

    @media (max-width: 982px) {
        grid-auto-rows: auto auto;
    }
`;

const ContainerText = styled.div`
    padding: 10px;
`;

const H1 = styled.h1`
    font-family: "Poppins", sans-serif;
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 0px;
    color: white;
`;

const P = styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: white;
`;

const Blocked: FC = () => {
    return (
        <Container>
            <ContainerText>
                <H1>The order is on-hold</H1>
                <P>Is no longer possible to customize the product.</P>
            </ContainerText>
        </Container>
    );
};

export default Blocked;