/**
 * @generated SignedSource<<ca9548f5536a860804fc9a85036d1291>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarketplaceOrderDetailStatus = "COMPLETE" | "NEW" | "WAITING" | "WAITING_FOR_SPLIT";
export type zakekeIframeMutation$variables = {
  accessToken: string;
  designDocID: string;
};
export type zakekeIframeMutation$data = {
  readonly updateMarketplaceOrderDetailSetDesign: {
    readonly designDocID: string | null;
    readonly id: string;
    readonly status: MarketplaceOrderDetailStatus;
  };
};
export type zakekeIframeMutation = {
  response: zakekeIframeMutation$data;
  variables: zakekeIframeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessToken"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "designDocID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accessToken",
        "variableName": "accessToken"
      },
      {
        "kind": "Variable",
        "name": "designDocID",
        "variableName": "designDocID"
      }
    ],
    "concreteType": "MarketplaceOrderDetail",
    "kind": "LinkedField",
    "name": "updateMarketplaceOrderDetailSetDesign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "designDocID",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "zakekeIframeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "zakekeIframeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "401535dea64f4ff16d936adee6946906",
    "id": null,
    "metadata": {},
    "name": "zakekeIframeMutation",
    "operationKind": "mutation",
    "text": "mutation zakekeIframeMutation(\n  $accessToken: String!\n  $designDocID: String!\n) {\n  updateMarketplaceOrderDetailSetDesign(accessToken: $accessToken, designDocID: $designDocID) {\n    id\n    status\n    designDocID\n  }\n}\n"
  }
};
})();

(node as any).hash = "01a97fcb5874e672d78bf94fca8e602d";

export default node;
