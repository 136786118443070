async function fetchGraphQL(url: string, text: any, variables: any, authToken?: string) {
  const headers = {
    'Content-Type': 'application/json',
  } as Record<string, string> ;

  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  return await response.json();
}

export default fetchGraphQL;