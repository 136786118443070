import { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Designer from './components/designer/designer';
import Complete from './components/designer/complete';
import Split from './components/designer/split';
import Skeleton from './components/skeleton';
import Blocked from './components/designer/blocked';
import Preview from './components/designer/preview';

function App() {
  return (
    <Suspense fallback={Skeleton}>
      <HashRouter>
        <Routes>
          <Route path="/design/:key" element={<Designer />} />
          <Route path="/complete/:key" element={<Complete />} />
          <Route path="/blocked/:key" element={<Blocked />} />
          <Route path="/split/:key" element={<Split />} />
          <Route path="/preview" element={<Preview />} />
        </Routes>
      </HashRouter>
    </Suspense>
  );
}

export default App;
