import { FunctionComponent, useState } from "react";
import { useFragment } from "react-relay/hooks";
// @ts-ignore
import graphql from 'babel-plugin-relay/macro';
import styled from "styled-components";
import { DesignPreviews_previews$key } from "./__generated__/DesignPreviews_previews.graphql";

const PreviewContainer = styled.div`
    display: grid;
    grid-auto-rows: auto 80px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px 0px, rgba(0, 0, 0, 0.06) 0px 10px 15px 0px;
    background: white;
    border-radius: 16px;
    margin: 20px;
`;

const MainPreview = styled.img`
    width: 100%;
`;

const PreviewSides = styled.div`
    margin-top: 20px;
`;

const ImageContainer = styled.div`
`;

const PreviewButton = styled.button`
    appearance: none;
    background: transparent;
    border: none;
    padding-left: 0;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
`;

const PreviewSide = styled.img`
    width: 48px;
    height: 48px;
    margin-right: 6px;
    object-fit: contain;
`;

interface Props {
    className?: string,
    designQuery: DesignPreviews_previews$key
}

const DesignPreviews: FunctionComponent<Props> = ({ className, designQuery }) => {
    const { previews } = useFragment<DesignPreviews_previews$key>(graphql`
        fragment DesignPreviews_previews on Design {
            previews {
                url
            }
        }
    `, designQuery);

    const [selectedPreview, setSelectedPreview] = useState(previews[0].url);

    return (
        <PreviewContainer className={className}>
            <ImageContainer>
                <MainPreview alt="Design preview" src={selectedPreview} />
            </ImageContainer>
            <PreviewSides>
                {previews.map(({ url }) => (
                    <PreviewButton key={url} onClick={() => setSelectedPreview(url)}>
                        <PreviewSide src={url} />
                    </PreviewButton>
                ))}
            </PreviewSides>
        </PreviewContainer>
    );
};

export default DesignPreviews;