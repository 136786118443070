import { FC, useCallback, useEffect } from 'react';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
// @ts-ignore
import graphql from 'babel-plugin-relay/macro';
import isMobile from './isMobile';
import { ZakekeMessage, isAddToCartMessage } from './zakekeMessage';
import { zakekeIframeQuery } from './__generated__/zakekeIframeQuery.graphql';
import { zakekeIframeMutation } from './__generated__/zakekeIframeMutation.graphql';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';

const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0;
`;

interface Props {
    accessToken: string,
    urlKey: string
}

const ZakekeIframe: FC<Props> = ({ urlKey, accessToken }) => {
    const { marketplaceOrderDetail } = useLazyLoadQuery<zakekeIframeQuery>(
        graphql`
          query zakekeIframeQuery($accessToken: String!) {
            marketplaceOrderDetail: marketplaceOrderDetailByAccessToken(accessToken: $accessToken) {
              id
              status
              iframeUrl {
                  largeUrl
                  mobileUrl
              }
              marketplaceOrder {
                  status
              }
            }
          }
        `,
        { accessToken },
    );

    if (!marketplaceOrderDetail) {
        throw new Error(`Invalid access token ${accessToken}`);
    }

    const [commitSetDesign] = useMutation<zakekeIframeMutation>(graphql`
        mutation zakekeIframeMutation(
            $accessToken: String!, $designDocID: String!
        ) {
            updateMarketplaceOrderDetailSetDesign (accessToken: $accessToken, designDocID: $designDocID) {
                id
                status
                designDocID
            }
        }
    `);

    const zakekeIframeUrl = isMobile() ? marketplaceOrderDetail.iframeUrl.mobileUrl : marketplaceOrderDetail.iframeUrl.largeUrl;

    const onMessageReceivedFromIframe = useCallback((event: MessageEvent<ZakekeMessage>) => {
        if (!isAddToCartMessage(event.data)) {
            return;
        }

        const key = 'zakekeAddToCartInProcess-' + event.data.designId;
        if (key in window) {
            return;
        }

        (window as any)[key] = true;

        commitSetDesign({
            variables: {
                accessToken,
                designDocID: event.data.designId
            }
        });
    }, [commitSetDesign, accessToken]);

    useEffect(() => {
        window.addEventListener("message", onMessageReceivedFromIframe);
        return () => window.removeEventListener("message", onMessageReceivedFromIframe);
    }, [onMessageReceivedFromIframe]);

    if (marketplaceOrderDetail.status === "COMPLETE") {
        return (
            <Navigate to={`/complete/${urlKey}`} replace />
        );
    }
    if (marketplaceOrderDetail.marketplaceOrder?.status === "BLOCKED") {
        return (
            <Navigate to={`/blocked/${urlKey}`} replace />
        );
    }
    if (marketplaceOrderDetail.status === "WAITING_FOR_SPLIT") {
        return (
            <Navigate to={`/split/${urlKey}`} replace />
        );
    }

    return (
        <StyledIframe allow="clipboard-read; clipboard-write; fullscreen; web-share; accelerometer; magnetometer; autoplay; encrypted-media; gyroscope; picture-in-picture; camera *; xr-spatial-tracking;" src={zakekeIframeUrl} />
    );
};

export default ZakekeIframe;