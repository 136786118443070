interface ZakekeMessageCommon {
    readonly zakekeMessageType: number
};

interface ZakekeMessageAddToCart extends ZakekeMessageCommon {
    readonly zakekeMessageType: 0,
    readonly designId: string
};

export type ZakekeMessage = ZakekeMessageCommon | ZakekeMessageAddToCart;

export const isAddToCartMessage = (message: ZakekeMessage): message is ZakekeMessageAddToCart  =>
    message.zakekeMessageType ===  0;